/* eslint-disable max-len,react/no-danger */
import React from "react";
import { PageHeader, Seo, Footer } from "components/common";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import styles from "components/commonStyles";
import { siteUrl } from "configs/constants";

function Sync({ classes }) {
  return (
    <>
      <Seo title="Synchronization and Caching" url={`${siteUrl}/sync`} />
      <PageHeader />
      <div className={classes.content}>
        <p style={{ marginBottom: 60 }}>
          What’s the idea of synchronization? How caching works? How data
          consistency is kept in concurrent environment? How to implement
          master-master synchronization?
        </p>
        <h4>Cache</h4>
        <p style={{ marginBottom: 20 }}>
          Srv4pos follows{" "}
          <span className={classes.textColor}>
            <a href="https://www.w3.org/Protocols/rfc2616/rfc2616-sec13.html">
              w3c recommendations
            </a>
          </span>
          . It means you can use{" "}
          <a href="https://www.w3.org/Protocols/rfc2616/rfc2616-sec14.html#sec14.21">
            <span className={classes.textColor}>Expires</span>
          </a>
          ,{" "}
          <a href="https://www.w3.org/Protocols/rfc2616/rfc2616-sec14.html#sec14.26">
            <span className={classes.textColor}>If-None-Match</span>
          </a>{" "}
          or{" "}
          <a href="https://www.w3.org/Protocols/rfc2616/rfc2616-sec14.html#sec14.21">
            <span className={classes.textColor}>If-Modified-Since</span>
          </a>{" "}
          headers. We recommend you to check out{" "}
          <a href="https://www.mnot.net/cache_docs/">
            <span className={classes.textColor}>this excellent web site</span>
          </a>{" "}
          that explains it.
        </p>
        <p style={{ marginBottom: 40 }}>
          <span className={classes.textHead}>Seller Version</span> is a source
          data for <span className={classes.textHead}>entity tags</span>. It is
          an autoincrement number starts from 0 and +1 each time you do a change
          on the server. So every change get own unique ordinal number within
          the same seller. Each Seller makes own counting. For instance:
        </p>
        <div style={{ marginBottom: 30 }}>
          <div className={classes.flex50}>
            <p className={classes.textHead} style={{ fontWeight: "500" }}>
              Action
            </p>
            <p className={classes.textHead} style={{ fontWeight: "500" }}>
              Seller version
            </p>
          </div>
          <div className={classes.flex50}>
            <p className={classes.textHead}>Seller created</p>
            <p className={classes.textHead}>0</p>
          </div>
          <div className={classes.flex50}>
            <p className={classes.textHead}>Added product FRUIT</p>
            <p className={classes.textHead}>1</p>
          </div>
          <div className={classes.flex50}>
            <p className={classes.textHead}>
              Added picture MAIN.JPG to product
            </p>
            <p className={classes.textHead}>2</p>
          </div>
          <div className={classes.flex50}>
            <p className={classes.textHead}>Product renamed to FRUIT_GREEN</p>
            <p className={classes.textHead}>3</p>
          </div>
          <div className={classes.flex50}>
            <p className={classes.textHead}>
              MAIN.JPG replaced with another pucture
            </p>
            <p className={classes.textHead}>4</p>
          </div>
        </div>
        <p style={{ marginBottom: 8 }}>
          Let’s imagine there are two users: James and Matt. James did the
          actions described in the table above. And Matt does some reading. Here
          are few examples how Matt can use cache:
        </p>
        <ul style={{ marginBottom: 30 }}>
          <li>James just added product FRUIT</li>
          <li>
            Matt requested list of available products and got one, then he
            requested this product and got FRUIT. This product was accompanied
            by response header ETag with value “1”. Because Seller version was
            “1”. Matt’s browser understood it’s tag and kept the response body
            in browser’s cache.
          </li>
          <li>
            Later on Matt opened up product FRUIT and his browser sends
            If-None-Match: “1” in the request. Srv4pos knows that nothing has
            been changed so far therefore Srv4pos responds with{" "}
            <a href="https://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html#sec10.3.5">
              <span className={classes.textColor}>HTTP status 304</span>
            </a>{" "}
            and empty body which forces Matt’s browser to open product FRUIT
            from it’s cache without any need to download the actual content.
          </li>
          <li>
            James made some changes, especially renamed the product to
            FRUIT_GREEN.
          </li>
          <li>
            Matt request product FRUIT again, accompanied by If-None-Match: “1”.
            But now server detects that the product’s ETag is “3”, not “1”.
            Which means it has been changed. Threfore server returns FRUIT_GREEN
            with ETag “3”. Matt’s browser downloads the changes and invalidates
            the cache with FRUIT_GREEN and ETag “3” for future use.
          </li>
        </ul>
        <p style={{ marginBottom: 8 }}>Similar situation with images:</p>
        <ul style={{ marginBottom: 30 }}>
          <li>James uploaded picture MAIN.JPG Mon, 16 Mar 2015 18:59:14 GMT</li>
          <li>
            Matt opened up a picture which was returned with response tag
            Last-Modified: Mon, 16 Mar 2015 18:59:14 GMT. It was saved to the
            browser’s cache.
          </li>
          <li>
            Matt opened up a picture again, now the browser sends
            If-Modified-Since: Mon, 16 Mar 2015 18:59:14 GMT to the server. And
            server responds 304. Which forces browser to open up image from it’s
            cache.
          </li>
          <li>
            James updated the picture MAIN.JPG Mon, 16 Mar 2015 12:11:12 GMT
          </li>
          <li>
            Matt opened up a picture again, now the browser sends
            If-Modified-Since: Mon, 16 Mar 2015 18:59:14 GMT to the server.
            Server compares the date with the latest date and understands there
            was a change made by James. Server returns actual content back to
            Matt.
          </li>
          <li>
            Matt’s browser downloads the image, displays a new one and
            invalidates the cache.
          </li>
        </ul>
        <p style={{ marginBottom: 8 }}>Headers support:</p>
        <ol style={{ marginBottom: 30 }}>
          <li>
            JSON data (like info about taxes, products and etc)
            <ul>
              <li>ETag, If-None-Match (limited, see below)</li>
            </ul>
          </li>
          <li>
            Pictures
            <ul>
              <li>ETag, If-None-Match</li>
              <li>Last-Modified, If-Modified-Since</li>
            </ul>
          </li>
          <li>
            Historical information about pictures. Srv4pos keeps all the
            versions of every picture uploaded. First James uploaded MAIN.JPG,
            then he updated it. So there are two different pictures: old one and
            new one. For the old picture Expires header is supported
            <ul>
              <li>Expires, default 24 hours</li>
              <li>ETag, If-None-Match</li>
              <li>Last-Modified, If-Modified-Since</li>
            </ul>
          </li>
        </ol>
        <p style={{ marginBottom: 8 }}>
          <span className={classes.textHead}>
            JSON data has limited ETag support
          </span>{" "}
          compared to Pictures. It’s better to show on example:
        </p>
        <ul style={{ marginBottom: 30 }}>
          <li>
            James created FRUIT and Matt downloaded it. Now Matt knows ETag is
            “1”.
          </li>
          <li>
            James uploaded picture, but not yet changed FRUIT to FRUIT_GREEN.
            Seller version becomes “2”.
          </li>
          <li>
            Matt requested FRUIT with If-None-Match: “1”. Server compares “1”
            with current seller version “2”, they are not equal and server
            thinks FRUIT has been changed, but it’s wrong! Instead it was a
            picture uploaded. Server responds HTTP status 200 instead of 304.
            Though it’s reasonable to return 304 because the product is not yet
            changed.
          </li>
        </ul>
        <p style={{ marginBottom: 8 }}>
          But pictures do not have this limitation:
        </p>
        <ul style={{ marginBottom: 30 }}>
          <li>James uploaded MAIN.JPG so seller version is “2”.</li>
          <li>Matt dowloads the picture accompanied with ETag “2”.</li>
          <li>
            James renames the product to FRUIT_GREEN and seller version becomes
            “3”.
          </li>
          <li>
            Matt opens the picture accompanied with If-None-Match “2”. Server
            works correctly here because in case of pictures, server does not
            look into global value of seller version, instead server discovers
            the specific seller’s version of the picture, which is still “2” and
            responds 304 correctly.
          </li>
        </ul>
        <p style={{ marginBottom: 30 }}>
          {
            "The limitation for JSON data is done in favour of performance. ETag for JSON data is very convenient for downloading big lists, such as GET /{sellerId}/products when you get big amount of data and does not really matter for single entityes such as GET /{sellerId}/products/{identifier}."
          }
        </p>
        <p style={{ marginBottom: 20 }}>
          In general we recommend you to enable caching both for JSON data and
          Pictures.
        </p>
        <p style={{ marginBottom: 20 }}>
          In case if something is still unclear, here is some more samples:
        </p>
        <p style={{ marginBottom: 30 }}>For requests on JSON data:</p>
        <div className={classes.tableMini} style={{ marginBottom: 30 }}>
          <div>
            <p className={classes.textHead}>If-none-match</p>
            <p className={classes.textHead}>seller version</p>
            <p className={classes.textHead}>HTTP status</p>
          </div>
          <div>
            <p>1</p>
            <p>1</p>
            <p>304</p>
          </div>
          <div>
            <p>1</p>
            <p>2</p>
            <p>200</p>
          </div>
          <div>
            <p>3</p>
            <p>4</p>
            <p>200</p>
          </div>
          <div>
            <p>4</p>
            <p>4</p>
            <p>304</p>
          </div>
        </div>
        <p style={{ marginBottom: 30 }}>For requests on picture:</p>
        <div className={classes.tableMini} style={{ marginBottom: 30 }}>
          <div>
            <p className={classes.textHead}>If-none-match</p>
            <p className={classes.textHead}>seller version</p>
            <p className={classes.textHead}>HTTP status</p>
          </div>
          <div>
            <p>2</p>
            <p>2</p>
            <p>304</p>
          </div>
          <div>
            <p>2</p>
            <p>3</p>
            <p>200</p>
          </div>
          <div>
            <p>2</p>
            <p>4</p>
            <p>304</p>
          </div>
          <div>
            <p>4</p>
            <p>4</p>
            <p>304</p>
          </div>
        </div>
        <p style={{ marginBottom: 20 }}>
          For JSON data current version of seller is shared during the cache
          validation, but cache validator for pictures looking for their own
          version, which is technically is a “snapshot” of seller version after
          the last modification onto picture in the past.
        </p>
        <p style={{ marginBottom: 60 }}>
          If you are java developer then use parameter Integer version same as
          ETag, and treat returned value “null” as 304 HTTP status code.
        </p>
        <h4>Bird’s eye view for the Synchronization</h4>
        <p style={{ marginBottom: 20 }}>
          The main idea of Synchronization is to establish a distributed
          environment with at least two different storages. One storage is on
          your side (e.g. database in your server, or database inside your POS),
          another storage at our server (cloud or local, doesn’t matter). And
          the requirement here: two databases are mostly contain equal set of
          data. Once it’s detected they are not equal the magic process of
          synchronization is running. And voila! They become equal!
        </p>
        <p style={{ marginBottom: 8 }}>
          Storage at your side is Client. Storage at our side is Server. The
          algorithm is very similar to any{" "}
          <a href="https://en.wikipedia.org/wiki/Version_control">
            <span className={classes.textColor}>Version Control Software</span>
          </a>
          :
        </p>
        <ul style={{ marginBottom: 60 }}>
          <li>
            To detect are the storages equal or not. Seller version is used.
            It’s an ordinal number and incremented each time some change happens
            in the data.
          </li>
          <li>
            Synchronization starts when: a) client polls up a server for
            “Server’s Seller Version” and it is different from “Client’s Seller
            version”. b) new data appears on Client. (We are working right now
            on some way to notify Client that “Server’s Seller Version” is
            changed.)
          </li>
          <li>
            First, Client must download changes from the Server and merge them
            into local storage somehow. It’s up to you to define your own merge
            strategy.
          </li>
          <li>Then, Client uploads his local changes up to the Server.</li>
        </ul>
        <h4>Master-slave synchronization algorithm</h4>
        <ol style={{ marginBottom: 30 }}>
          <li>
            Get latest seller version number using {"/{sellerId}/version"} or{" "}
            getThisVersion(…) .
          </li>
          <li>
            Compare with your local seller version (if it’s not yet set, set it
            to zero by default).
          </li>
          <li>
            If they don’t match, request all the changes between your local
            version and server version via {"/{sellerId}/modifications"} or{" "}
            getMidifcations(…) . And save them to local database.
          </li>
        </ol>
        <p>
          If you want to synchronize single entity, e.g. taxes, this request can
          be made via {"/{sellerId}/taxes-diff/{version-from}/{version-to}"} or{" "}
          listDiff(…) . Every entity has own “diff” method.
        </p>
      </div>
      <Footer />
    </>
  );
}

Sync.propTypes = {
  classes: PropTypes.shape({
    content: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    textHead: PropTypes.string.isRequired,
    flex50: PropTypes.string.isRequired,
    tableMini: PropTypes.string.isRequired
  }).isRequired
};

export default withStyles(styles, { withTheme: true })(Sync);
